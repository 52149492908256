// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());


window.$crisp = [];
window.CRISP_WEBSITE_ID = "a5f840c0-ab66-44cf-95c8-cf1e8444cadd";
(function () { d = document; s = d.createElement("script"); s.src = "https://client.crisp.chat/l.js"; s.async = 1; d.getElementsByTagName("head")[0].appendChild(s); })();
(function () {
  $crisp.push(['do', 'chat:hide']);
  $crisp.push(["on", "chat:closed", function () {
    $crisp.push(['do', 'chat:hide']);
  }]);

  var chatBtns = document.getElementsByClassName('btn-chat');
  for (var i = 0; i < chatBtns.length; i++) {
    chatBtns[i].addEventListener('click', function (evt) {
      evt.preventDefault();
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'chat:show']);
    })
  }
})();

window.ga = function () { ga.q.push(arguments) }; ga.q = []; ga.l = +new Date;
ga('create', 'UA-174767001-1', 'auto'); ga('set', 'anonymizeIp', true); ga('set', 'transport', 'beacon'); ga('send', 'pageview')
